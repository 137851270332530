
import { computed, defineComponent, reactive, ref, watch } from "vue";
import { useHead } from "@vueuse/head";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";

import CSpinner from "@/components/spinner.vue";
import { formatDate, formatDateEn, formatDateLong, addMoneyMask } from "@/utils/formatters";

const VReschedule = defineComponent({
  name: "VReschedule",
  components: { CSpinner },
  setup() {
    useHead({ title: "Reagendar | obmed" });
    const store = useStore();
    const route = useRoute();
    const router = useRouter();

    const allSpecialist = computed(() => store.state.specialist.dateList);
    const allSpecialistDate = computed(() => store.state.specialist.dates);
    const selectedDate = ref<string | null>(String(route.query.date) || null);

    const loading = reactive({ list: false });

    function handleSelectedDate(date: string) {
      selectedDate.value = formatDate(date);
    }

    async function getAllSpecialistByDate() {
      loading.list = true;
      await store.dispatch("getAllSpecialistByDate", {
        specialtyID: String(route.query.sy),
        establishmentID: String(route.query.et),
        date: formatDateEn(String(selectedDate.value)),
      });
      loading.list = false;
    }

    async function getAllSpecialistDate() {
      loading.list = true;
      await store.dispatch("getAllSpecialistDate", {
        specialtyID: String(route.query.sy),
        establishmentID: String(route.query.et),
      }),
        (loading.list = false);

      if (!allSpecialistDate.value?.find((date) => formatDate(date) === selectedDate.value))
        selectedDate.value = formatDate(allSpecialistDate.value[0]);

      getAllSpecialistByDate();
    }

    watch(selectedDate, async (date) => {
      await getAllSpecialistByDate();
      router.replace({ query: { ...route.query, date } });
    });

    if (!route.query.sy || !route.query.et) router.back();
    else getAllSpecialistDate();

    return {
      allSpecialist,
      allSpecialistDate,
      selectedDate,
      loading,
      handleSelectedDate,
      formatDate,
      formatDateLong,
      addMoneyMask,
    };
  },
});

export default VReschedule;
